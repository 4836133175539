import { PrimaryCtaEnum } from 'generated/graphql';

interface CtaConfigOption {
    label: string;
    labelTitleCase: string;
    labelShort: string;
}

type CtaEnumMapType = {
    [K in PrimaryCtaEnum]: CtaConfigOption;
};

interface CtaEnumMap extends CtaEnumMapType {
    default: CtaConfigOption;
}

export interface CtaTopConfigInterface<T> {
    project: T;
    product: T;
    professional: T;
    article: T;
}

export const PROFESSIONAL_SECTIONS_ANCHOR = 'sections';

interface DefaultCtaConfigInterface
    extends CtaTopConfigInterface<CtaConfigOption> {}

interface PrimaryCtaConfigInterface extends CtaTopConfigInterface<CtaEnumMap> {}

export const PRIMARY_CTA_CONFIG_UNDEFINED_PAGETYPE: CtaConfigOption = {
    label: 'Message professional',
    labelTitleCase: 'Message Professional',
    labelShort: 'Message professional',
};

export const PRIMARY_CTA_CONFIG_DEFAULTS: DefaultCtaConfigInterface = {
    product: {
        label: 'Request price / brochure',
        labelTitleCase: 'Request Price / Brochure',
        labelShort: 'Request price',
    },
    project: {
        label: 'Message professional',
        labelTitleCase: 'Message Professional',
        labelShort: 'Message professional',
    },
    professional: {
        label: 'Request a meeting',
        labelTitleCase: 'Request a Meeting',
        labelShort: 'Get in touch',
    },
    article: {
        label: 'Message professional',
        labelTitleCase: 'Message Professional',
        labelShort: 'Message professional',
    },
};

export const PRIMARY_CTA_MANAFACTURER: CtaConfigOption = {
    label: 'Request Price/Brochure',
    labelTitleCase: 'Request price/brochure',
    labelShort: PRIMARY_CTA_CONFIG_DEFAULTS.professional.labelShort,
};

export const PRIMARY_CTA_CONFIG: PrimaryCtaConfigInterface = {
    product: {
        [PrimaryCtaEnum.AutoOptimise]: PRIMARY_CTA_CONFIG_DEFAULTS.product,
        [PrimaryCtaEnum.GetInTouch]: {
            label: 'Get in touch',
            labelTitleCase: 'Get in Touch',
            labelShort: 'Get in touch',
        },
        [PrimaryCtaEnum.MessageSeller]: {
            label: 'Message seller',
            labelTitleCase: 'Message Seller',
            labelShort: 'Message seller',
        },
        [PrimaryCtaEnum.RequestBrochure]: {
            label: 'Request a brochure',
            labelTitleCase: 'Request Brochure',
            labelShort: 'Request brochure',
        },
        [PrimaryCtaEnum.RequestMeeting]: {
            label: 'Request a meeting',
            labelTitleCase: 'Request a Meeting',
            labelShort: 'Request meeting',
        },
        [PrimaryCtaEnum.RequestPrice]: {
            label: 'Request price',
            labelTitleCase: 'Request Price',
            labelShort: 'Request price',
        },
        [PrimaryCtaEnum.RequestQuote]: {
            label: 'Request a quote',
            labelTitleCase: 'Request a Quote',
            labelShort: 'Request quote',
        },
        [PrimaryCtaEnum.AskAQuestion]: {
            label: 'Ask a question',
            labelTitleCase: 'Ask a Question',
            labelShort: 'Ask a question',
        },
        [PrimaryCtaEnum.BookConsultation]: PRIMARY_CTA_CONFIG_DEFAULTS.product,
        default: PRIMARY_CTA_CONFIG_DEFAULTS.product,
    },
    professional: {
        [PrimaryCtaEnum.AskAQuestion]: {
            label: 'Ask a question',
            labelTitleCase: 'Ask a Question',
            labelShort: 'Ask a question',
        },
        [PrimaryCtaEnum.AutoOptimise]: PRIMARY_CTA_CONFIG_DEFAULTS.professional,
        [PrimaryCtaEnum.GetInTouch]: {
            label: 'Get in touch',
            labelTitleCase: 'Get in Touch',
            labelShort: 'Get in touch',
        },
        [PrimaryCtaEnum.MessageSeller]: {
            label: 'Message seller',
            labelTitleCase: 'Message Seller',
            labelShort: 'Message seller',
        },
        [PrimaryCtaEnum.RequestBrochure]: {
            label: 'Request brochure',
            labelTitleCase: 'Request Brochure',
            labelShort: 'Request brochure',
        },
        [PrimaryCtaEnum.RequestMeeting]: {
            label: 'Request a meeting',
            labelTitleCase: 'Request a Meeting',
            labelShort: 'Request meeting',
        },
        [PrimaryCtaEnum.RequestPrice]: {
            label: 'Request price',
            labelTitleCase: 'Request Price',
            labelShort: 'Request price',
        },
        [PrimaryCtaEnum.RequestQuote]: {
            label: 'Request a quote',
            labelTitleCase: 'Request a Quote',
            labelShort: 'Request quote',
        },
        [PrimaryCtaEnum.BookConsultation]: {
            label: 'Book a consultation',
            labelTitleCase: 'Book a Consultation',
            labelShort: 'Book a consultation',
        },
        default: PRIMARY_CTA_CONFIG_DEFAULTS.professional,
    },
    project: {
        [PrimaryCtaEnum.AskAQuestion]: PRIMARY_CTA_CONFIG_DEFAULTS.project,
        [PrimaryCtaEnum.AutoOptimise]: PRIMARY_CTA_CONFIG_DEFAULTS.project,
        [PrimaryCtaEnum.GetInTouch]: PRIMARY_CTA_CONFIG_DEFAULTS.project,
        [PrimaryCtaEnum.MessageSeller]: PRIMARY_CTA_CONFIG_DEFAULTS.project,
        [PrimaryCtaEnum.RequestBrochure]: PRIMARY_CTA_CONFIG_DEFAULTS.project,
        [PrimaryCtaEnum.RequestMeeting]: PRIMARY_CTA_CONFIG_DEFAULTS.project,
        [PrimaryCtaEnum.RequestPrice]: PRIMARY_CTA_CONFIG_DEFAULTS.project,
        [PrimaryCtaEnum.RequestQuote]: PRIMARY_CTA_CONFIG_DEFAULTS.project,
        [PrimaryCtaEnum.BookConsultation]: PRIMARY_CTA_CONFIG_DEFAULTS.project,
        default: PRIMARY_CTA_CONFIG_DEFAULTS.project,
    },
    article: {
        [PrimaryCtaEnum.AskAQuestion]: PRIMARY_CTA_CONFIG_DEFAULTS.article,
        [PrimaryCtaEnum.AutoOptimise]: PRIMARY_CTA_CONFIG_DEFAULTS.article,
        [PrimaryCtaEnum.GetInTouch]: PRIMARY_CTA_CONFIG_DEFAULTS.article,
        [PrimaryCtaEnum.MessageSeller]: PRIMARY_CTA_CONFIG_DEFAULTS.article,
        [PrimaryCtaEnum.RequestBrochure]: PRIMARY_CTA_CONFIG_DEFAULTS.article,
        [PrimaryCtaEnum.RequestMeeting]: PRIMARY_CTA_CONFIG_DEFAULTS.article,
        [PrimaryCtaEnum.RequestPrice]: PRIMARY_CTA_CONFIG_DEFAULTS.article,
        [PrimaryCtaEnum.RequestQuote]: PRIMARY_CTA_CONFIG_DEFAULTS.article,
        [PrimaryCtaEnum.BookConsultation]: PRIMARY_CTA_CONFIG_DEFAULTS.article,
        default: PRIMARY_CTA_CONFIG_DEFAULTS.article,
    },
};
